import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "antd";
import { fetchInterviewQuestions, Question } from "../../../api/questions";

interface ChooseInterviewQuestionProps {
  onNext: (question: any) => void;
  roles: string[];
}

const ChooseInterviewQuestion: React.FC<ChooseInterviewQuestionProps> = ({
  onNext,
  roles, // Destructure roles
}) => {
  const [question, setQuestion] = useState("");

  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionValue, setQuestionValue] = useState<string>("");

  const handleNext = () => {
    onNext(question);
  };

  const initQuestion = async () => {
    try {
      console.log("Roles passed to fetchInterviewQuestions:", roles); // Debug: Print the roles
      const { questions } = await fetchInterviewQuestions({
        limit: 100,
        offset: 0,
        roles,
        orderBy: "created_at",
      });
      console.log("Fetched questions:", questions); // Debug: Print the fetched questions
      setQuestions(questions);
    } catch (error) {
      console.error("Error fetching interview questions:", error);
    }
  };

  const questionsChange = (value: string) => {
    setQuestionValue(value);
    setQuestion(value);
  };

  useEffect(() => {
    initQuestion();
  }, [roles]); // Re-fetch questions when roles change

  return (
    <div className="flex flex-col items-center justify-center gap-4">
{/*       <h2>Choose Interview Question</h2>
      <Select
        style={{ width: '100%', maxWidth: '32rem' }} // 32rem corresponds to max-w-lg in Tailwind CSS
        value={questionValue}
        onChange={questionsChange}
        options={questions.map((q) => ({
          value: q.title,
          label: q.title,
        }))}
        showSearch
        placeholder="Select a question"
        filterOption={(input, option) =>
          option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
        }
      /> */}
      <h2>Type The Interview Question</h2>
      <Input.TextArea
        style={{ width: '100%', maxWidth: '32rem' }} // Apply the same style here
        rows={5}
        placeholder="Enter your question. e.g. 'Design a twitter feed system'"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      /> 
      
      <Button type="primary" className="w-full max-w-xs" onClick={handleNext}>
        Next
      </Button>
    </div>
  );
};

export default ChooseInterviewQuestion;

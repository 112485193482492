import React, { useState } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { supabase } from "../lib/supabaseClient";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const SignUpModal = ({ visible, onCancel }: Props) => {
  const [loading, setLoading] = useState(false);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState("");

  const handleSubmit = async (values) => {
    setLoading(true);
    const { email, password } = values;

    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });

    setLoading(false);

    if (error) {
      notification.error({
        message: "Registration Failed",
        description: error.message,
      });
    } else {
      setRegisteredEmail(email);
      setShowVerifyEmailModal(true);
    }
  };

  const handleVerifyEmailModalClose = () => {
    setShowVerifyEmailModal(false);
    onCancel(); // Close the main signup modal
  };

  return (
    <>
      <Modal
        title="Sign Up"
        open={visible}
        width={400}
        onCancel={onCancel}
        footer={null}
      >
        <Form name="signup" onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                min: 6,
                message: "Password must be at least 6 characters long!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Register
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Verify Your Email"
        open={showVerifyEmailModal}
        onOk={handleVerifyEmailModalClose}
        onCancel={handleVerifyEmailModalClose}
        footer={[
          <Button key="ok" type="primary" onClick={handleVerifyEmailModalClose}>
            OK
          </Button>,
        ]}
      >
        <p>Thank you for registering!</p>
        <p>We've sent a verification email to: <strong>{registeredEmail}</strong></p>
        <p>Please check your email and click on the verification link to complete your registration.</p>
      </Modal>
    </>
  );
};

export default SignUpModal;
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      navigate("/");
    }
  }, [countdown, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Result
        status="404"
        title="404"
        subTitle={`The page you are looking for does not exist. Redirecting in ${countdown} seconds...`}
        extra={
          <Button
            onClick={() => navigate("/")}
            type="primary"
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
import { supabase } from "../lib/supabaseClient";

export interface UserSettings {
  language: string;
  verbosity: string;
}

export const updateUserSettings = async (userId: string, settings: UserSettings): Promise<void> => {
  console.log('Upserting user settings:', settings);
  const { error } = await supabase
    .from('profiles')
    .upsert({
      user_id: userId,
      language: settings.language,
      verbosity: settings.verbosity
    }, {
      onConflict: 'user_id'
    });

  if (error) {
    console.error('Error upserting user settings:', error);
    throw error;
  }
};

export const getUserSettings = async (userId: string): Promise<UserSettings | null> => {
  const { data, error } = await supabase
    .from('profiles')
    .select('language, verbosity')
    .eq('user_id', userId)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      // User not found
      return null;
    }
    throw error;
  }

  return data;
};

export const isPro = async (userId: string): Promise<boolean> => {
  const { data: subscriptionData, error: subscriptionError } = await supabase
    .from('subscriptions')
    .select('status, current_period_end')
    .eq('user_id', userId)
    .single();

  if (subscriptionError && subscriptionError.code !== 'PGRST116') {
    console.error('Error checking subscription status:', subscriptionError);
    throw subscriptionError;
  }

  const hasActiveSubscription = subscriptionData?.status === 'active' && new Date(subscriptionData.current_period_end) > new Date();

  if (hasActiveSubscription) {
    return true;
  }

  const { data: profileData, error: profileError } = await supabase
    .from('profiles')
    .select('role')
    .eq('user_id', userId)
    .single();

  if (profileError && profileError.code !== 'PGRST116') {
    console.error('Error checking user role:', profileError);
    throw profileError;
  }

  if (!profileData) {
    console.warn('No profile data found for user:', userId);
    return false;
  }

  return profileData.role === 'admin' || profileData.role === 'pro';
};

// New functions to get and add user total sessions
export const getUserTotalInterviews = async (userId: string): Promise<number> => {
  const { data, error } = await supabase
    .from('user_stats')
    .select('num_interviews')
    .eq('user_id', userId)
    .single();

  if (error) {
    console.error('Error fetching user total sessions:', error);
    throw error;
  }

  return data?.num_interviews || 0;
};

export const addTotalInterviews = async (userId: string): Promise<void> => {
  const { data, error } = await supabase
    .rpc('increment_num_interviews', { p_user_id: userId });

  if (error) {
    console.error('Error incrementing user total sessions:', error);
    throw error;
  }
};
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { countGoalsByUser } from '../api/goals';
import { countDocumentsByUser } from '../api/documents';
import { countInterviewsByUser } from '../api/interviews';
import { Link } from 'react-router-dom';
import '../styles/Dashboard.css';

const Dashboard: React.FC = () => {
  const { session } = useAuth();
  const [totalGoals, setTotalGoals] = useState(0);
  const [totalInterviews, setTotalInterviews] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  useEffect(() => {
    if (session?.user) {
      fetchGoals();
      fetchDocuments();
      fetchInterviews();
    }
  }, [session]);

  const fetchGoals = async () => {
    if (!session?.user) return;
    try {
      const total = await countGoalsByUser(session.user.id);
      setTotalGoals(total);
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  const fetchDocuments = async () => {
    if (!session?.user) return;
    try {
      const total = await countDocumentsByUser(session.user.id);
      setTotalFiles(total);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const fetchInterviews = async () => {
    if (!session?.user) return;
    try {
      const total = await countInterviewsByUser(session.user.id);
      setTotalInterviews(total);
    } catch (error) {
      console.error('Error fetching interviews:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <p>Last 30 days</p>
      <div className="dashboard-stats">
        <div className="stat-card">
          <h2>Total Aced Interviews</h2>
          <Link to="/interview">
            <p>{totalInterviews}</p>
          </Link>
        </div>
        <div className="stat-card">
          <h2>Total Goals Set</h2>
          <Link to="/goals">
            <p>{totalGoals}</p>
          </Link>
        </div>
        <div className="stat-card">
          <h2>Total Files Generated</h2>
          <Link to="/documents">
            <p>{totalFiles}</p>
          </Link>
        </div>
      </div>
      {/* Add more content here as needed */}
    </div>
  );
};

export default Dashboard;
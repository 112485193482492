
import { ReactNode } from "react";


/* eslint-disable @typescript-eslint/no-explicit-any */
export type IProps = {
  onNext: (item: any) => void;
  onBack?: () => void;
}

export type ScreenShareParamsType = {
  audioStream?: MediaStream | null,
  videoStream?: MediaStream | null,
  isSharing?: boolean,
  handleStart?: () => void,
  handleStop?: () => void,
  videoRef?: React.RefObject<HTMLVideoElement>,
  screenAudioStatus?: AudioStatusEnum,
  microphoneStatus?: AudioStatusEnum
}


export enum SocketStatusEnum {
  CONNECTING = "Connecting",
  OPEN = "Connected",
  CLOSING = "Disconnecting",
  CLOSED = "Disconnected",
  ERROR = "Error",
}

export enum AudioStatusEnum {
  UNAVAILABLE = "Unavailable",
  AVAILABLE = "Available",
  ERROR = "Error",
}


export type StateType = {
  renderStatus: ReactNode;
  socketStatus: SocketStatusEnum;
  microphoneStatus: AudioStatusEnum;
  screenAudioStatus: AudioStatusEnum
}
import React, { useEffect } from 'react';
import { Modal, Form, Select, Radio } from 'antd';
import { UserSettings } from '../../../api/users';

type Props = {
  open: boolean;
  onCancel: () => void;
  onOk: (values: UserSettings) => void;
  initialValues: UserSettings | null;
};
const selectOptions = [
  { value: "en-US", label: "English (US)" },
  { value: "en-AU", label: "English (Australia)" },
  { value: "en-GB", label: "English (UK)" },
  { value: "en-NZ", label: "English (New Zealand)" },
  { value: "en-IN", label: "English (India)" },
  { value: "bg", label: "Bulgarian" },
  { value: "ca", label: "Catalan" },
  { value: "zh", label: "Chinese (Mandarin, Simplified)" },
  { value: "zh-CN", label: "Chinese (Mainland China)" },
  { value: "zh-Hans", label: "Chinese (Simplified)" },
  { value: "zh-TW", label: "Chinese (Taiwan)" },
  { value: "zh-Hant", label: "Chinese (Traditional)" },
  { value: "cs", label: "Czech" },
  { value: "da", label: "Danish" },
  { value: "da-DK", label: "Danish (Denmark)" },
  { value: "nl", label: "Dutch" },
  { value: "et", label: "Estonian" },
  { value: "fi", label: "Finnish" },
  { value: "nl-BE", label: "Flemish" },
  { value: "fr", label: "French" },
  { value: "fr-CA", label: "French (Canada)" },
  { value: "de", label: "German" },
  { value: "de-CH", label: "German (Switzerland)" },
  { value: "el", label: "Greek" },
  { value: "hi", label: "Hindi" },
  { value: "hu", label: "Hungarian" },
  { value: "id", label: "Indonesian" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "ko", label: "Korean" },
  { value: "ko-KR", label: "Korean (South Korea)" },
  { value: "lv", label: "Latvian" },
  { value: "lt", label: "Lithuanian" },
  { value: "ms", label: "Malay" },
  { value: "multi", label: "Multilingual (Spanish + English)" },
  { value: "no", label: "Norwegian" },
  { value: "pl", label: "Polish" },
  { value: "pt", label: "Portuguese" },
  { value: "pt-BR", label: "Portuguese (Brazil)" },
  { value: "ro", label: "Romanian" },
  { value: "ru", label: "Russian" },
  { value: "sk", label: "Slovak" },
  { value: "es", label: "Spanish" },
  { value: "es-419", label: "Spanish (Latin America)" },
  { value: "sv", label: "Swedish" },
  { value: "sv-SE", label: "Swedish (Sweden)" },
  { value: "th", label: "Thai" },
  { value: "th-TH", label: "Thai (Thailand)" },
  { value: "tr", label: "Turkish" },
  { value: "uk", label: "Ukrainian" },
  { value: "vi", label: "Vietnamese" },
];

const defaultSettings: UserSettings = {
  language: 'en-US',
  verbosity: 'medium'
};


export const InterviewSettingsModal: React.FC<Props> = ({ open, onCancel, onOk, initialValues }) => {
  const [form] = Form.useForm<UserSettings>();

  useEffect(() => {
    form.setFieldsValue(initialValues || defaultSettings);
  }, [initialValues, form]);

  return (
    <Modal
      title="Interview Settings"
      open={open}
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then((values) => {
          onOk(values);
        });
      }}
    >
      <Form form={form} layout="vertical" initialValues={defaultSettings}>
        <Form.Item name="language" label="Language">
          <Select>
            {selectOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="verbosity" label="Verbosity">
          <Radio.Group>
            <Radio value="concise">Concise</Radio>
            <Radio value="medium">Medium</Radio>
            <Radio value="verbose">Verbose</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
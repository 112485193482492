import React, { useEffect } from "react";
import { Modal, Button, Avatar } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  signOut: () => void;
}

export function ProfileModal({
  isOpen,
  onClose,
  user,
  signOut,
}: ProfileModalProps) {
  useEffect(() => {
    console.log("User object:", user);
  }, [user]);

  return (
    <Modal open={isOpen} onCancel={onClose} title="Welcome back!" footer={null}>
      <div className="flex flex-col items-center">
        <Avatar
          size={64}
          src={user.user_metadata.avatar_url}
          icon={<UserOutlined />}
        />
        <p className="mt-4 text-center">
          {user.user_metadata.full_name && <span>{user.user_metadata.full_name}</span>}
          {user.user_metadata.full_name && user.email && <br />}
          {user.email && <span>{user.email}</span>}
        </p>
        <div className="mt-4 flex flex-col gap-2 w-full">
          {/*           <Button className="w-full" type="default">
            <UserOutlined className="mr-2" />
            Manage Account
          </Button>
          <Button className="w-full" type="default">
            <QuestionCircleOutlined className="mr-2" />
            Support
          </Button> */}
          <Button className="w-full" type="default" onClick={signOut}>
            <LogoutOutlined className="mr-2" />
            Logout
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function ProfileIcon() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <div>
      <Avatar
        size="large"
        icon={<UserOutlined />}
        onClick={handleOpenModal}
        className="cursor-pointer"
      />
      <ProfileModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
}

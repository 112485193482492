import { supabase } from "../lib/supabaseClient";
import { Database } from "../database.types";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getBaseUrl } from "./utils";

export type Document = Database["public"]["Tables"]["documents"]["Row"];
type DocumentInsert = Database["public"]["Tables"]["documents"]["Insert"];

const UPLOAD_BUCKET = "uploads";

export const createDocument = async (
  file: File,
  filename: string,
  documentType: string,
  userId: string,
  isActivated: boolean
) => {
  const fileExtension = filename.split(".").pop();
  const uuidFilename = `${uuidv4()}.${fileExtension}`;

  const { error: fileError } = await supabase.storage
    .from(UPLOAD_BUCKET)
    .upload(`${userId}/${uuidFilename}`, file);

  if (fileError) {
    throw new Error(fileError.message);
  }

  const {
    data: { publicUrl },
  } = await supabase.storage
    .from(UPLOAD_BUCKET)
    .getPublicUrl(`${userId}/${uuidFilename}`);

  const document: DocumentInsert = {
    file_name: filename,
    file_type: documentType,
    user_id: userId,
    file_url: publicUrl,
    is_activated: isActivated,
  };

  const { data, error } = await supabase
    .from("documents")
    .insert(document)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  axios.post(`${getBaseUrl()}/users/${userId}/resume`).catch((extractError) => {
    console.error(`Failed to extract resume: ${extractError.response?.data?.detail || extractError.message}`);
  });

  return data;
};

export const listDocumentsByUser = async (
  userId: string,
  page: number = 1,
  pageSize: number = 10
): Promise<{ documents: Document[]; total: number }> => {
  const from = (page - 1) * pageSize;
  const to = from + pageSize - 1;

  // Fetch documents with pagination
  const { data: documents, error } = await supabase
    .from("documents")
    .select("*", { count: "exact" })
    .eq("user_id", userId)
    .range(from, to)
    .order("created_at", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  // Fetch the total count of documents for the user
  const { count } = documents;

  return { documents: documents as Document[], total: count };
};

export const countDocumentsByUser = async (userId: string): Promise<number> => {
  const { count, error } = await supabase
    .from("documents")
    .select("*", { count: "exact", head: true })
    .eq("user_id", userId);

  if (error) {
    throw new Error(error.message);
  }

  return count || 0;
};

// Read a document by ID
export const getDocumentById = async (id: string): Promise<Document> => {
  const { data, error } = await supabase
    .from("documents")
    .select("*")
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data as Document;
};

export const deleteDocument = async (id: string) => {
  // Retrieve the document to get the file path
  const { data: document, error: fetchError } = await supabase
    .from("documents")
    .select("file_url")
    .eq("id", id)
    .single();

  if (fetchError) {
    throw new Error(fetchError.message);
  }

  if (!document) {
    throw new Error("Document not found");
  }

  // Extract the file path from the URL
  const filePath = document.file_url.split("/").slice(-2).join("/");

  // Delete the file from storage
  const { error: storageError } = await supabase.storage
    .from(UPLOAD_BUCKET)
    .remove([filePath]);

  if (storageError) {
    throw new Error(storageError.message);
  }

  // Delete the document record from the database
  const { data, error: deleteError } = await supabase
    .from("documents")
    .delete()
    .eq("id", id)
    .single();

  if (deleteError) {
    throw new Error(deleteError.message);
  }

  return data;
};

export const updateDocument = async (id: string, updates: Partial<DocumentInsert>) => {
  const { data, error } = await supabase
    .from("documents")
    .update(updates)
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
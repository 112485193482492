import React, { useState } from "react";
import { Form, Input, Button, Space, Select, message } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { createGoal } from "../../api/goals";
import { useAuth } from "../../contexts/AuthContext";

type Props = {
  next: () => void;
};
const jobCategories = [
  { value: "software", label: "💻 Software" },
  { value: "data_science", label: "📊 Data Science" },
  { value: "product_management", label: "💡 Product Management" },
  { value: "finance", label: "💰 Finance" },
  { value: "consulting", label: "👥 Consulting" },
  { value: "marketing", label: "📣 Marketing" },
  { value: "sales", label: "💼 Sales" },
  { value: "design", label: "🎨 Design" },
  { value: "customer_support", label: "🎧 Customer Support" },
  { value: "others", label: "🔹 Others" },
];

const Step2: React.FC<Props> = ({ next }) => {
  const [form] = Form.useForm();

  const { session } = useAuth();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      console.log("Form values:", values);
      // 这里可以添加表单提交逻辑
      await createHandler(values);
      next(); // 提交成功后跳转到下一步
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const autoFillSample = () => {
    form.setFieldsValue({
      category: "Engineering",
      company: "Google",
      position: "Software Engineer",
    });
  };

  const createHandler = async (values: any) => {
    if (!session) return;
    setLoading(true);
    const o: any = {
      category: values.category,
      company: values.company,
      position: values.position,
    };
    return createGoal({ ...o, user_id: session.user.id })
      .then(() => {
        message.success("Job application created successfully!");
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center bg-gray-200">
      <div className="w-full p-4 bg-white  w-[500px]">
        <div className="text-center mb-6">
          <Space>
            <BookOutlined className="text-2xl text-blue-600 mb-2" />
            <h2 className="text-2xl font-bold">Upcoming Interview</h2>
          </Space>
          <p className="text-gray-400">
            Tell Sensei about the next interview that you want to ace.
          </p>
        </div>

        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
        >
          <Form.Item
            name="category"
            label="Job Category"
            rules={[
              { required: true, message: "Please select a job category!" },
            ]}
          >
            <Select
              placeholder="Select a job category"
              options={jobCategories}
            />
          </Form.Item>

          <Form.Item
            name="position"
            label="Position"
            rules={[{ required: true, message: "Please input the position!" }]}
          >
            <Input placeholder="What position are you interviewing for?" />
          </Form.Item>

          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true, message: "Please input the company!" }]}
          >
            <Input placeholder="Which company you are interviewing for?" />
          </Form.Item>

          <div className="flex justify-end mb-4 mt-[-20px]">
            <Button
              type="link"
              onClick={autoFillSample}
              className="px-0 text-gray-500 text-sm"
            >
              Autofill Sample Job
            </Button>
          </div>

          <Form.Item wrapperCol={{ span: 24 }} className="text-center w-full">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-[300px] mb-[-20px]"
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Step2;

import { Empty, EmptyProps } from "antd";

const NoDataView = (props: EmptyProps) => {
  return (
    <div className="h-full flex justify-center items-center">
      <Empty {...(props ? props : {})} />
    </div>
  );
};

export default NoDataView;

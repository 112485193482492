import { Modal, Button, Row, Col } from "antd";

interface LanguageModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectLanguage: (language: string) => void;
}

const languages = [
  "Java", "Python", "JavaScript", "C++", "C", "Go", "Ruby", "Swift",
  "TypeScript", "Kotlin", "Rust", "PHP", "Scala", "R", "SQL"
];

export function LanguageModal({ isVisible, onClose, onSelectLanguage }: LanguageModalProps) {
  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null} title="Select a Programming Language">
      <Row gutter={[16, 16]}>
        {languages.map((language) => (
          <Col key={language} xs={12} sm={8} md={6} lg={4}>
            <Button className="w-full" onClick={() => onSelectLanguage(language)}>
              {language}
            </Button>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}
import React, { useState } from 'react';
import { supabase } from "../lib/supabaseClient";
import { Button, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import "../styles/Auth.css";

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setLoading(true);
    const { error } = await supabase.auth.updateUser({ password: newPassword });
    
    if (error) {
      message.error(error.message);
    } else {
      message.success('Password updated successfully');
      navigate('/login');
    }
    setLoading(false);
  };

  return (
    <div className="auth-container">
      <div className="auth-form-container">
        <h1 className="auth-header">Reset Your Password</h1>
        <p className="auth-subheader">Enter your new password below</p>
        <div className="auth-form">
          <Input.Password
            className="auth-input"
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            className="auth-button"
            onClick={handleResetPassword}
            loading={loading}
          >
            Reset Password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
import { useState } from "react";
import {
  VideoCameraOutlined,
  CodeOutlined,
  AppstoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { IProps } from "../types";
import { LanguageModal } from "./LanguageModal";
import mixpanel from "../../../lib/mixpanel";

const allInterviewTypes = [
  {
    key: "general",
    title: "General Interview",
    description: "General topics such as behavioral or experience-based questions.",
    icon: <VideoCameraOutlined className="text-4xl" />,
  },
  {
    key: "management",
    title: "Management Interview",
    description: "Leadership, team management, and strategic planning.",
    icon: <UserOutlined className="text-4xl" />,
  },
  {
    key: "coding",
    title: "Coding Interview",
    description: "Coding, algorithms, and problem-solving assessment.",
    icon: <CodeOutlined className="text-4xl" />,
  },
  {
    key: "system_design",
    title: "System Design",
    description: "Design scalable, efficient, and reliable software systems.",
    icon: <AppstoreOutlined className="text-4xl" />,
  },
  {
    key: "ml_system_design",
    title: "ML System Design",
    description: "Design machine learning systems and pipelines.",
    icon: <AppstoreOutlined className="text-4xl" />,
  },
  {
    key: "product_management",
    title: "Product Management",
    description: "Strategic thinking, prioritization, and product vision.",
    icon: <UserOutlined className="text-4xl" />,
  },
];


export default function ChooseInterviewType({ onNext, selectedGoal }: IProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);

  // Filter interview types based on selected goal
  const interviewTypes = selectedGoal
    ? allInterviewTypes.filter((type) =>
        selectedGoal.interviewTypes.includes(type.key)
      )
    : [];

  function handleNext(item) {
    mixpanel.track("Interview Type Selected", { type: item.title });
    if (item.key === "coding") {
      setSelectedInterview(item);
      setIsModalVisible(true);
    } else {
      onNext(item);
    }
  }

  function handleSelectLanguage(language) {
    setIsModalVisible(false);
    onNext({ ...selectedInterview, language });
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        {interviewTypes.map((item) => (
          <Col key={item.key} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              className="h-full cursor-pointer"
              onClick={() => handleNext(item)}
            >
              <div className="flex items-center gap-4">
                <div className="text-indigo-500">{item.icon}</div>
                <div>
                  <h3 className="text-lg font-bold">{item.title}</h3>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <LanguageModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSelectLanguage={handleSelectLanguage}
      />
    </>
  );
}
import React, { useState, useRef } from "react";
import { Button, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { useAuth } from "../../contexts/AuthContext";
import { createDocument } from "../../api/documents";

const { Dragger } = Upload;

const Step3 = ({ next }: { next: () => void }) => {
  const [file, setFile] = useState<RcFile | null>(null);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const uploadRef = useRef<any>(null);

  const { session } = useAuth();

  const [uploading, setUploading] = useState(false);

  const [filename, setFilename] = useState("");

  const handleUpload = async () => {
    if (!file) return message.error("Please upload a PDF file.");
    if (!session?.user.id) return;
    console.log("file", file);

    setUploading(true);
    try {
      await createDocument(file, file.name, file.type, session.user.id, true);
      message.success("Document uploaded successfully");
      next();
    } catch (error) {
      console.error("Error uploading document:", error);
      message.error("Failed to upload document");
    } finally {
      setUploading(false);
    }
  };

  const handleBeforeUpload = (file: File) => {
    console.log("file", file);
    if (file.type !== "application/pdf") {
      message.error("Please upload a PDF file.");
      return Upload.LIST_IGNORE;
    }
    if (file.size > 5 * 1024 * 1024) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    setFilename(file.name);
    return false;
  };

  const handleSkip = () => {
    // Handle skip action here
    console.log("Skip button clicked");
    next();
  };

  return (
    <div className="relative flex flex-col items-center justify-center p-4">
      <div className="w-full flex justify-end mb-4">
        <Button
          type="text"
          size="small"
          onClick={handleSkip}
          className="text-gray-400 hover:text-gray-700"
        >
          Skip
        </Button>
      </div>
      <h2 className="text-2xl font-bold mb-2">
        <span role="img" aria-label="pencil">
          📝
        </span>{" "}
        Upload Resume
      </h2>
      <p className="text-gray-400 mb-4">
        Sensei customizes responses based on your profile and experiences.
      </p>
      <Dragger
        ref={uploadRef}
        accept=".pdf"
        fileList={fileList}
        maxCount={1}
        name="file"
        beforeUpload={handleBeforeUpload}
        className="w-full max-w-lg rounded-lg"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag your resume here to upload
        </p>
        <p className="ant-upload-hint">
          Please upload your resume in a PDF format.
        </p>
      </Dragger>
      {filename && (
        <div className="mt-4">
          <p>Filename: {filename}</p>
        </div>
      )}
      <Button
        loading={uploading}
        type="primary"
        onClick={() => handleUpload()}
        className="mt-4 w-[300px]"
      >
        Next
      </Button>
    </div>
  );
};

export default Step3;

import { supabase } from "../lib/supabaseClient";
import { Database } from "../database.types";
import { QueryData } from '@supabase/supabase-js'

// Add this enum at the top of the file
export enum InterviewType {
  CODING = 'coding',
  SYSTEM_DESIGN = 'system_design',
  BEHAVIORAL = 'behavioral'
}

export enum InterviewStatus {
  NOT_STARTED = 'not_started',
  INTERVIEWING = 'interviewing',
  FINISHED = 'finished'
}

const interviewQuery = supabase
  .from('interviews')
  .select(`
    *, goals (*), documents (*)
  `);

type InterviewWithGoal = QueryData<typeof interviewQuery>[number];
export type Interview = Omit<InterviewWithGoal, 'goals'> & { 
  goal: InterviewWithGoal['goals'];
  interview_type: InterviewType;
};

export type Message = Database["public"]["Tables"]["messages"]["Row"];

export type InterviewInsert = Omit<Database["public"]["Tables"]["interviews"]["Insert"], "interview_type"> & {
  interview_type: InterviewType;
};

type InterviewUpdate = Database["public"]["Tables"]["interviews"]["Update"];

// Create a new interview
export const createInterview = async (interview: InterviewInsert): Promise<Interview> => {
  const { data, error } = await supabase.from("interviews").insert(interview).select().single();

  if (error) {
    throw error;
  }
  return data;
};

// Retrieve an interview by ID
export const getInterviewById = async (id: string): Promise<Interview | null> => {
  const query = interviewQuery.eq('id', id).single();
  const { data, error } = await query;

  if (error) {
    if (error.code === 'PGRST116') {
      // Interview not found
      return null;
    }
    throw error;
  }

  return {
    ...data,
    goal: data.goals,
  };
};

// List interviews by user ID with pagination
export const listInterviewsByUser = async (
  userId: string,
  status: InterviewStatus | null = null,
  page: number = 1,
  pageSize: number = 10
): Promise<{ interviews: Interview[]; total: number }> => {
  const from = (page - 1) * pageSize;
  const to = from + pageSize - 1;

  let query = interviewQuery.eq('user_id', userId);
  
  if (status) {
    query = query.eq('status', status);
  }

  query = query.range(from, to);

  const { data, error, count } = await query;

  if (error) {
    throw error;
  }

  const interviews = data?.map(interview => ({
    ...interview,
    goal: interview.goals,
    goals: undefined
  })) as Interview[];

  return { interviews, total: count || 0 };
};

// Count interviews by user ID
export const countInterviewsByUser = async (userId: string): Promise<number> => {
  const { count, error } = await supabase
    .from("interviews")
    .select("*", { count: "exact", head: true })
    .eq("user_id", userId);

  if (error) {
    throw error;
  }

  return count || 0;
};

// Update an interview by ID
export const updateInterview = async (
  id: string,
  updates: InterviewUpdate
): Promise<Interview> => {
  // Remove goal from updates if it exists
  const { goal, ...cleanUpdates } = updates;

  const { data, error } = await supabase
    .from("interviews")
    .update(cleanUpdates)
    .eq("id", id)
    .select('*')
    .single();

  if (error) {
    throw error;
  }

  return data;
};

// Delete an interview by ID
export const deleteInterview = async (id: string): Promise<Interview> => {
  const { data, error } = await supabase
    .from("interviews")
    .delete()
    .eq("id", id)
    .single();

  if (error) {
    throw error;
  }

  return data;
};

export const updateInterviewStatus = async (
  interviewId: string,
  status: InterviewStatus
): Promise<void> => {
  const updateData: { status: InterviewStatus; start_at?: string } = { status };

  if (status === InterviewStatus.INTERVIEWING) {
    updateData.start_at = new Date().toISOString();
  }

  const { error } = await supabase
    .from('interviews')
    .update(updateData)
    .eq('id', interviewId)
    .is('start_at', null);

  if (error) {
    throw error;
  }
}

export const getMessages = async (interviewId: string): Promise<Message[]> => {
  const { data, error } = await supabase
    .from('messages')
    .select('*')
    .eq('interview_id', interviewId)
    .order('created_at', { ascending: true });

  if (error) {
    throw error;
  }

  return data as Message[];
};
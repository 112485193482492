import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import { useAuth } from "./AuthContext";
import { isPro, getUserTotalInterviews } from "../api/users";

interface UserStatusContextType {
  isProUser: boolean | null;
  totalInterviews: number | null;
  fetchProStatus: (userId: string) => Promise<void>;
  fetchTotalInterviews: (userId: string) => Promise<void>;
}

const UserStatusContext = createContext<UserStatusContextType | undefined>({
  isProUser: null,
  totalInterviews: 0,
  fetchProStatus: async () => {},
  fetchTotalInterviews: async () => {},
});

export const UserStatusProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isProUser, setIsProUser] = useState<boolean | null>(null);
  const [totalInterviews, setTotalInterviews] = useState<number | null>(null);
  const { session } = useAuth();
  const fetchingProRef = useRef(false);
  const fetchingInterviewsRef = useRef(false);

  const fetchProStatus = async (userId: string) => {
    if (fetchingProRef.current) return;
    fetchingProRef.current = true;

    try {
      const proStatus = await isPro(userId);
      setIsProUser(proStatus);
    } catch (error) {
      console.error("Error fetching pro status:", error);
      setIsProUser(false);
    } finally {
      fetchingProRef.current = false;
    }
  };

  const fetchTotalInterviews = async (userId: string) => {
    if (fetchingInterviewsRef.current) return;
    fetchingInterviewsRef.current = true;

    try {
      const interviews = await getUserTotalInterviews(userId);
      setTotalInterviews(interviews);
    } catch (error) {
      console.error("Error fetching total interviews:", error);
      setTotalInterviews(0);
    } finally {
      fetchingInterviewsRef.current = false;
    }
  };

  useEffect(() => {
    if (session?.user) {
      if (isProUser === null && !fetchingProRef.current) {
        fetchProStatus(session.user.id);
      }
      if (totalInterviews === null && !fetchingInterviewsRef.current) {
        fetchTotalInterviews(session.user.id);
      }
    }
  }, [session, isProUser, totalInterviews]);

  return (
    <UserStatusContext.Provider
      value={{
        isProUser,
        totalInterviews,
        fetchProStatus,
        fetchTotalInterviews,
      }}
    >
      {children}
    </UserStatusContext.Provider>
  );
};

export const useUserStatus = () => {
  const context = useContext(UserStatusContext);
  if (context === undefined) {
    return {
      isProUser: null,
      totalInterviews: 0,
      fetchProStatus: async () => {},
      fetchTotalInterviews: async () => {},
    };
  }
  return context;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, Steps, Form, message } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import SelectJob from "./components/SelectJob";
import ChooseInterviewType from "./components/ChooseInterviewType";
import Next3 from "./components/ConnectMeeting";
import ChooseInterviewQuestion from "./components/ChooseInterviewQuestion"; // Import the new component
import { createInterview, InterviewStatus } from "../../api/interviews";
import { useAuth } from "../../contexts/AuthContext";
import {
  updateUserSettings,
  getUserSettings,
  UserSettings,
  addTotalInterviews,
} from "../../api/users";
import { InterviewSettingsModal } from "./components/InterviewSettingsModal";
import ChatFrame from "./components/ChatFrame";
import { useUserStatus } from "../../contexts/UserStatusContext";
import { categoryToRolesMap } from "./constants";

const InterviewsNew = () => {
  const authSession = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isMockInterview = location.pathname === "/customize-mock-interview";

  const stepsItems = [
    {
      title: "Select Job",
    },
    {
      title: "Choose Interview Type",
    },
    ...(isMockInterview
      ? [{ title: "Choose Interview Question" }]
      : []),
    {
      title: "Connect Meeting",
    },
  ];

  const [current, setCurrent] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [settingsForm] = Form.useForm();

  const paramsRef = useRef<any>({});

  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const [screenShareParams, setScreenShareParams] = useState<any>();
  const screenShareParamsRef = useRef<any>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [roles, setRoles] = useState<string[]>([]);

  const [defaultShowVideo, setDefaultShowVideo] = useState(false);

  const { isProUser, totalInterviews, fetchTotalInterviews } = useUserStatus();

  const onChange = (value: number) => {
    if (value > current) return;
    setCurrent(value);
  };

  const handleSettingsModalOpen = async () => {
    if (!authSession.session?.user) {
      console.error("No user logged in");
      return;
    }

    try {
      const settings = await getUserSettings(authSession.session.user.id);
      setUserSettings(settings);
      setIsSettingsModalOpen(true);
    } catch (error) {
      console.error("Error loading settings:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleSettingsModalClose = () => {
    setIsSettingsModalOpen(false);
  };

  const handleSettingsSave = async (values: UserSettings) => {
    if (!authSession.session?.user) {
      console.error("No user logged in");
      return;
    }

    try {
      await updateUserSettings(authSession.session.user.id, values);
      setUserSettings(values);
      setIsSettingsModalOpen(false);
      console.log("Settings saved:", values);
    } catch (error) {
      console.error("Error saving settings:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleLaunchInterview = async () => {
    if (!authSession.session) return;
    console.log(isProUser, totalInterviews);
    /*     if (!isProUser && (totalInterviews !== null && totalInterviews > 3)) {
          return message.info(
            "You have reached the maximum number of interviews for your plan. Please upgrade to a Pro plan to continue.",
            10
          );
        } */

    try {
      setLoading(true);

      const settings = await getUserSettings(authSession.session.user.id);
      const interviewData = {
        user_id: authSession.session.user.id,
        interview_type: paramsRef.current.interviewType.key,
        coding_language: paramsRef.current.interviewType.language || null,
        goal_id: paramsRef.current.goal.id,
        settings: {
          language: settings?.language,
          verbosity: settings?.verbosity,
        },
        status: InterviewStatus.NOT_STARTED,
        is_mock: isMockInterview,
        question: isMockInterview ? paramsRef.current.interviewQuestion : null,
      };

      const res = await createInterview(interviewData);
      console.log(res);

      // Increment the total number of interviews for the user
      await addTotalInterviews(authSession.session.user.id);

      setIsModalOpen(true);
      setScreenShareParams({
        ...screenShareParamsRef.current,
        interview: res,
      });
    } catch (error) {
      console.error("Error launching interview:", error);
      // Optionally, show an error message to the user
    } finally {
      setLoading(false);
      // refetch total interviews
      fetchTotalInterviews(authSession.session.user.id);
    }
  };

  const onStopScreenSharing = () => {
    if (screenShareParams?.interview?.id) {
      navigate(`/feedback/${screenShareParams?.interview?.id}`);
    }
  };

  const handleSelectJobNext = (goal: any) => {
    paramsRef.current = { goal };
    setSelectedGoal(goal);
    setDisabled(true);
    setCurrent(current + 1);

    // Set roles based on the selected job category
    console.log("Selected goal:", goal);
    setRoles(categoryToRolesMap[goal.category] || []);
  };

  const readerNext = (
    <>
      <div className={`h-full ${current === 0 ? "show" : "hidden"}`}>
        <SelectJob onNext={handleSelectJobNext} />
      </div>
      <div className={`h-full ${current === 1 ? "show" : "hidden"}`}>
        <ChooseInterviewType
          onNext={(interviewType: any) => {
            setCurrent(current + 1);
            setDisabled(true);
            paramsRef.current = {
              ...paramsRef.current,
              interviewType,
            };
          }}
          selectedGoal={selectedGoal}
        />
      </div>
      {isMockInterview && (
        <div className={`h-full ${current === 2 ? "show" : "hidden"}`}>
          <ChooseInterviewQuestion
            onNext={(e: any) => {
              setCurrent(current + 1);
              setDisabled(true);
              paramsRef.current = {
                ...paramsRef.current,
                interviewQuestion: e,
              };
            }}
            roles={roles} // Pass roles to ChooseInterviewQuestion
          />
        </div>
      )}
      {/* <div className={`h-full ${current === (isMockInterview ? 3 : 2) ? "show" : "hidden"}`}> */}
      {current === (isMockInterview ? 3 : 2) ? <Next3
        onNext={(e: boolean) => {
          setDisabled(!e);
        }}
        // onPrevious={() => {
        //   setCurrent(current - 1);
        // }}
        onScreenShareParams={(e: any) => {
          console.log("Screen share params:", e);
          setScreenShareParams(e);
          screenShareParamsRef.current = e;
        }}
        onLaunchInterview={handleLaunchInterview}
        launchInterviewLoading={loading}
        onStopScreenSharing={onStopScreenSharing}
        isMockInterview={isMockInterview}
        defaultShowVideo={defaultShowVideo}
        setDefaultShowVideo={setDefaultShowVideo}
      /> : <></>}
      {/* </div> */}
    </>
  );

  return (
    <div className="flex gap-4 items-center justify-center w-full h-[calc(100vh-20px)] ">
      <Card className="w-[350px] h-full">
        <div className="mb-8">
          <h1 className="text-xl font-bold ">
            {isMockInterview ? "Mock Interview" : "AI Interview Assistant"}
          </h1>
          <p className="text-sm text-gray-500">
            {isMockInterview ? "Prepare for your mock interview" : "Ace your live interview today"}
          </p>
        </div>
        <Steps
          current={current}
          onChange={onChange}
          direction="vertical"
          items={stepsItems}
        />
        <Button
          icon={<SettingOutlined />}
          onClick={handleSettingsModalOpen}
          className="mt-4 w-full"
        >
          Interview Settings
        </Button>
      </Card>
      <div className="flex-1 w-full h-full">
        <Card className="h-full w-full overflow-auto">{readerNext}</Card>
      </div>

      <InterviewSettingsModal
        open={isSettingsModalOpen}
        onCancel={handleSettingsModalClose}
        onOk={handleSettingsSave}
        initialValues={userSettings}
      />
      {isModalOpen && (
        <ChatFrame
          handleCancel={() => {
            setIsModalOpen(false);
          }}
          handleOk={() => {
            setIsModalOpen(false);
          }}
          isModalOpen={isModalOpen}
          screenShareParams={screenShareParams}
          isMockInterview={isMockInterview}
          defaultShowVideo={defaultShowVideo}
        />
      )}
    </div>
  );
};

export default InterviewsNew;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Input, Form, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { Goal } from "../../../api/goals";
import { jobCategories } from "../../InterviewsNew/constants";

type IProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formValues: any;
  form: FormInstance<any>;
  onFinish: (e: Goal) => void;
  isEditing: boolean;
};

const CreateModal = ({
  open,
  setOpen,
  onFinish,
  form,
  formValues,
  isEditing,
}: IProps) => {
  return (
    <Modal
      width={550}
      open={open}
      title={isEditing ? "Edit a job application" : "Add a new job application"}
      okText={isEditing ? "Update" : "Create"}
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
        form: "form_in_modal",
      }} // Added form attribute
      onCancel={() => setOpen(false)}
      destroyOnClose
    >
      <p className="mb-4">
        Share your job position and company details with the AI to create
        intelligent interview plans.
      </p>
      <Form
        // layout="vertical"
        layout="horizontal"
        form={form}
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
        clearOnDestroy
        labelCol={{ span: 6 }} // 设置标签的宽度为6个栅格
        wrapperCol={{ span: 18 }} // 设置表单项内容的宽度为18个栅格
        onFinish={(values) =>
          onFinish({
            ...formValues,
            ...values,
          })
        }
      >
        <Form.Item
          name="category"
          label="Job Category"
          rules={[{ required: true, message: "Please select a job category!" }]}
        >
          <Select placeholder="Select a job category" options={jobCategories} />
        </Form.Item>
        <Form.Item
          name="position"
          label="Position"
          rules={[{ required: true, message: "Please input the position!" }]}
        >
          <Input placeholder="Enter your job position. e.g. Software Engineer" />
        </Form.Item>
        <Form.Item
          name="company"
          label="Company"
          rules={[
            { required: true, message: "Please input the company name!" },
          ]}
        >
          <Input placeholder="Enter the company name. e.g. Google" />
        </Form.Item>
        <Form.Item name="company_details" label="Company Details">
          <Input.TextArea
            showCount
            maxLength={20000}
            placeholder="Copy and paste the company description here"
            rows={4}
          />
        </Form.Item>
        <Form.Item name="job_description" label="Job Description">
          <Input.TextArea
            maxLength={20000}
            showCount
            rows={4}
            placeholder="Copy and paste the job description here"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateModal;

import { RouterProvider } from "react-router-dom";
import { router } from "./routers";
import { AuthProvider } from "./contexts/AuthContext";
import { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import ReactGA from "react-ga4";
import WelcomeTour from "./components/WelcomeTour";
import * as Sentry from "@sentry/react";
import mixpanel from "./lib/mixpanel";
import { UserStatusProvider } from "./contexts/UserStatusContext";

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn:
      "https://1df7597f5b1d05d7e95d5db6729082fd@o4507970410971136.ingest.us.sentry.io/4507970518450176",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://aceinterview-backend.onrender.com/",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  const [primary] = useState("#6366f1");

  useEffect(() => {
    if (import.meta.env.MODE === "production") {
      ReactGA.initialize("G-21W1VDK274");
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      mixpanel.track("Page Viewed", { page: window.location.pathname });
    }
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryBg: "#EBF0FA",
            colorPrimary: "#535CB2",
            fontFamily: "var(--font-nacelle)",
            borderRadius: 12,
            colorWarning: "#FE673A",
            colorSuccess: "#6bd5b4",
            colorError: "#f5222d",
            colorText: "#1e244d",
            colorTextSecondary: "#9a9cad",
            colorTextQuaternary: "#dbe0e7",
            colorBgContainer: "#EBF0FA",
          },
          components: {
            Menu: {
              itemSelectedBg: "rgba(171, 171, 255, 0.2)",
              collapsedIconSize: 22,
              iconSize: 20,
              fontSizeLG: 22,
            },
            Card: {
              colorBgContainer: "#fff",
            },
            Table: {
              colorBgContainer: "#fff",
            },
            Select: {
              selectorBg: "#fff",
            },
            Input: {
              colorBgContainer: "#fff",
            },
          },
        }}
        componentSize={"large"}
      >
        <AuthProvider>
          <>
            <UserStatusProvider>
              <RouterProvider router={router} />
            </UserStatusProvider>
          </>
        </AuthProvider>
      </ConfigProvider>
    </>
  );
}

export default App;

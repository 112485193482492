import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";

interface CustomTableProps<T> extends TableProps<T> {
  columns: ColumnsType<T>;
  dataSource: T[];
  loading?: boolean;
}

const CustomTable = ({
  columns,
  dataSource,
  loading,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CustomTableProps<any>) => {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={{ pageSize: 10 }}
      scroll={{ x: true }}
    />
  );
};

export default CustomTable;

import { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../lib/supabaseClient";
import { Session } from "@supabase/supabase-js";
import { Spin } from "antd";

interface AuthContextType {
  session: Session | null;
}

const whiteList = [
  "/login",
  "/register",
  "/auth",
  "/play",
  "/share",
  "/interview",
  "/404",
];

const getIsAuthByLocal =
  localStorage.length &&
  Object.keys(localStorage).some((key) => key.includes("auth-token"));

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const isPass = whiteList.some(
    (v) =>
      window.location.pathname.includes(v) &&
      window.location.pathname !== "/interview"
  );

  const fetchSession = async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    setSession(session);
    setLoading(false);
    if (!session && !isPass) {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  if (loading) {
    return <Spin />;
  }

  if (!getIsAuthByLocal && !isPass) {
    window.location.href = "/login";
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ session }}>{children}</AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };

import React from "react";
import { Button } from "antd";

const Subscribe = () => {
  const startModel = (
    <div className="absolute w-[650px] h-[400px] bg-gray-100 bg-opacity-50 backdrop-filter backdrop-blur-lg">
      <div className="bg-gray-500 bg-opacity-50 p-8 rounded-lg shadow-lg text-center h-full w-full flex flex-col items-center justify-center">
        <h1 className="text-white text-2xl font-bold">Sensei AI Playground</h1>
        <p className="text-white mt-4 w-[300px]">
          The ultimate solution to experiment and get comfortable with our AI
          before using it in real interviews
        </p>
        <Button
          type="primary"
          className="mt-8 px-4 py-2 rounded-lg hover:slow-in hover:scale-110 transition-all duration-300"
        >
          Get Started
        </Button>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center mt-16">
      <h1 className="text-2xl font-bold mb-4">Subscribe Now</h1>
      <p className="text-l mb-8 text-gray-700">
        Unlock Exclusive Access to the AI Playground by Subscribing Today!
      </p>
      <div className="relative w-[650px] h-[400px] rounded-lg border-1 bg-gray-100">
        {/* <img src={image} alt="Subscribe" className="w-full h-full rounded-lg" /> */}
        {startModel}
      </div>
    </div>
  );
};

export default Subscribe;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  message,
  Modal,
  Space,
  Switch,
  Alert,
  Slider,
  Dropdown,
  Menu,
  Spin,
  Layout,
} from "antd";
import {
  ExclamationCircleOutlined,
  DownOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState, useCallback } from "react";
import useWebSocket from "../hooks/useWebSocket";
import {
  useMediaRecorder,
  mediaDataHandler,
  DataQueueType,
} from "../hooks/useMediaRecorder";
import MarkdownRenderer from "../../../components/MarkdownRenderer";
import { SocketStatusEnum } from "../types";
import { formatTime } from "../../../utils";
import { useNavigate } from "react-router-dom";
import "./ChatFrame.css"; // Import the custom CSS file
import { useUserStatus } from "../../../contexts/UserStatusContext";

const { Sider, Content } = Layout;

type Message = {
  id: string;
  text: string;
  speaker_id?: string;
  start?: number;
  role?: string;
  speech_final?: boolean;
  animationClass?: string;
};


type Props = {
  screenShareParams: any;
  isModalOpen: boolean;
  isMockInterview: boolean;
  defaultShowVideo: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const END_TIMEOUT = 15 * 60 * 1000; //15 * 60 * 1000;

const ChatFrame = ({
  screenShareParams,
  isModalOpen: isChatFrameOpen,
  isMockInterview,
  defaultShowVideo,
  handleOk,
  handleCancel,
}: Props) => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [copilotMessagesMap, setCopilotMessagesMap] = useState<
    Map<string, Message>
  >(new Map());
  const [transcriptMessagesMap, setTranscriptMessagesMap] = useState<
    Map<string, Message>
  >(new Map());
  const copilotEndRef = useRef<HTMLDivElement | null>(null);
  const transcriptEndRef = useRef<HTMLDivElement | null>(null);

  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const [copilotAutoScroll, setCopilotAutoScroll] = useState<boolean>(true);
  const [transcriptAutoScroll, setTranscriptAutoScroll] = useState<boolean>(
    true
  );


  const [fontSize, setFontSize] = useState<number>(14); // Add font size state
  const [time, setTime] = useState<number>(0);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number | null>(null); // Add countdown state
  const [loading, setLoading] = useState<boolean>(true); // Add loading state

  const disableScreenshotRef = useRef<boolean>(false);
  const [endTime, setEndTime] = useState<number>(END_TIMEOUT);
  const { isProUser } = useUserStatus();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [hideIntervieweeMessages, setHideIntervieweeMessages] = useState(false);
  const [hideVideo, setHideVideo] = useState<boolean>(false); // Add hide video state
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false); // Add state for dropdown visibility
  const currentAudioRef = useRef<HTMLAudioElement | null>(null); // Add ref for current audio

  const [isCopilotAtBottom, setIsCopilotAtBottom] = useState<boolean>(true);
  const [isTranscriptAtBottom, setIsTranscriptAtBottom] = useState<boolean>(true);

  const [collapsed, setCollapsed] = useState<boolean>(false); // Add state for Sider collapse

  const [showBackgroundPrompt, setShowBackgroundPrompt] = useState(false);

  const [hideTranscript, setHideTranscript] = useState<boolean>(false);

  const handleDropdownVisibleChange = (flag: boolean) => {
    setDropdownVisible(flag);
  };


  const onMessage = (e: any) => {
    try {
      const data = JSON.parse(e.data);
      if (data.type === "audio") {
        console.log("play audio");
        if (currentAudioRef.current) {
          currentAudioRef.current.pause();
          currentAudioRef.current = null;
        }
        const audio = new Audio(data.data);
        currentAudioRef.current = audio;
        audio.play();
        return;
      }
      // Handle countdown messages
      if (data.type === "countdown") {
        console.log(`Session will end in ${data.remaining} seconds.`);
        setCountdown(data.remaining); // Update countdown state
        return;
      }

      if (data.type === "timeout") {
        console.log(`Session is timeout.`);
        endInterview(); // Update countdown state
        return;
      }

      if (data.type === "ready") {
        setLoading(false); // Update loading state
        return;
      }

      // Handle copilot and transcript messages (as before)
      processWebSocketMessage(data);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
    }
  };


  const updateMessageMap = (
    prevMap: Map<string, Message>,
    newMessage: Message
  ): Map<string, Message> => {
    const updatedMap = new Map(prevMap);
    const existingMessage = updatedMap.get(newMessage.id);

    if (existingMessage) {
      updatedMap.set(newMessage.id, {
        ...existingMessage,
        text: existingMessage.text + newMessage.text,
        speech_final: newMessage.speech_final,
        animationClass: "",
      });
    } else {
      updatedMap.set(newMessage.id, newMessage);
    }

    return updatedMap;
  };

  const processWebSocketMessage = (data: any) => {
    // Change role to "me" if the role is "interviewee"
    if (data.role === "interviewee") {
      data.role = "me";
    }

    if (data.role === "ai") {
      setCopilotMessagesMap((prevMap) => updateMessageMap(prevMap, data));
    } else {
      // Handle transcript message
      setTranscriptMessagesMap((prevMap) => updateMessageMap(prevMap, data));
    }
  };
  const addAnimationClass = (msg: Message) => {
    const updateMap = (map: Map<string, any>, item: any) => {
      item.animationClass = null;
      map.set(msg.id, item);
      setTranscriptMessagesMap(new Map(map));

      setTimeout(() => {
        item.animationClass = "fade-animation";
        map.set(msg.id, item);
        setTranscriptMessagesMap(new Map(map));
      }, 50);
    };


    const item1 = copilotMessagesMap.get(msg.id);
    const item2 = transcriptMessagesMap.get(msg.id);

    if (item1) updateMap(copilotMessagesMap, item1);
    if (item2) updateMap(transcriptMessagesMap, item2);
  };


  const onMaxErrorCount = () => {
    console.log("Max error count reached");
    // handleStop();
    message.error("Socket connection error, please try again later.");
    // handleCancel();
    setTimeout(() => {
      navigate(`/feedback/${screenShareParams?.interview?.id}`);
    }, 3000);
  };

  const dataQueueRef = useRef<DataQueueType[]>([]);

  const { socketStatus, initSocket, socketRef, closeSocket } = useWebSocket(
    screenShareParams?.interview?.id,
    onMessage,
    screenShareParams.isSharing,
    onMaxErrorCount
  );


  const { sendMediaData, processQueue } = mediaDataHandler(
    socketRef,
    dataQueueRef
  );
  const { startRecording, stopRecording } = useMediaRecorder(sendMediaData);

  const handleEndInterview = () => {
    Modal.confirm({
      title: "Are you sure you want to end the interview?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      onOk() {
        endInterview();
      },
      onCancel() {
        // Do nothing if the user cancels
      },
    });
  };

  const handleStop = () => {
    setCountdown(null); // Reset the countdown
    closeSocket(() => {
      stopRecording();
      screenShareParams?.handleStop();
      setTime(0);
    });
  };

  const endInterview = () => {
    handleStop();
    // handleCancel(); // Close the ChatFrame modal
    // setTimeout(() => {
    //   navigate(`/feedback/${screenShareParams?.interview?.id}`);
    // }, 300);
  };


  useEffect(() => {
    if (!screenShareParams?.isSharing) {
      handleStop();
      handleCancel();
    }
  }, [screenShareParams?.isSharing]);

  useEffect(() => {
    if (!isChatFrameOpen) {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current = null;
      }
      return;
    }



    if (videoRef.current && screenShareParams) {

      videoRef.current.srcObject = screenShareParams?.videoStream;
    }

    initSocket(true, processQueue);

    return () => {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current = null;
      }
    };
  }, [videoRef, screenShareParams, isChatFrameOpen, loading, hideVideo]);

  useEffect(() => {
    if (isMockInterview) {
      setHideVideo(!defaultShowVideo);
    }
  }, [isMockInterview, defaultShowVideo]);

  useEffect(() => {
    if (!screenShareParams) return;

    if (loading) return; // Don't start recording if loading is true

    if (time > 0) {
      // Stop previous recording and clear the data queue
      stopRecording();
      dataQueueRef.current.splice(0, dataQueueRef.current.length);
      console.log("Cleared dataQueue", dataQueueRef.current);
    }


    if (
      socketStatus === SocketStatusEnum.OPEN &&
      screenShareParams.isSharing &&
      !loading // Ensure loading is false before starting
    ) {
      setTime((prevTime) => prevTime + 1000);
      startRecording(
        screenShareParams?.videoStream?.getVideoTracks(),
        screenShareParams?.combinedAudioStream?.getAudioTracks()
      );
    }
  }, [screenShareParams, socketStatus, loading]);

  const handleScroll = useCallback(
    (ref: React.RefObject<HTMLDivElement>, setIsAtBottom: (value: boolean) => void) => {
      if (ref.current) {
        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 10;
        setIsAtBottom(atBottom);
        if (atBottom) {
          setCopilotAutoScroll(true);
          setTranscriptAutoScroll(true);
        } else {
          setCopilotAutoScroll(false);
          setTranscriptAutoScroll(false);
        }
      }
    },
    []
  );

  // const scrollToBottom = useCallback(
  //   (ref: React.RefObject<HTMLDivElement>, setIsAtBottom: (value: boolean) => void) => {
  //     setTimeout(() => {
  //       if (ref.current) {
  //         ref.current.scrollTo({
  //           top: ref.current.scrollHeight,
  //           behavior: "smooth",
  //         });
  //         setIsAtBottom(true);
  //       }
  //     }, 400);
  //   },
  //   []
  // );

  const scrollToBottom = useCallback(
    (ref: React.RefObject<HTMLDivElement>, setIsAtBottom: (value: boolean) => void) => {
      const attemptScroll = () => {
        if (ref.current) {
          ref.current.scrollTo({
            top: ref.current.scrollHeight + 100,
            behavior: "smooth",
          });


          // Check if the scroll is at the bottom
          const { scrollTop, scrollHeight, clientHeight } = ref.current;
          const atBottom = scrollTop + clientHeight >= scrollHeight - 10;

          if (!atBottom) {
            // If not at bottom, try scrolling again
            setTimeout(attemptScroll, 100);
          } else {
            setIsAtBottom(true);
          }
        }
      };

      setTimeout(attemptScroll, 400);
    },
    []
  );

  const handleMessageClick = (msg: Message) => {
    if (!copilotEndRef.current) return;
    const targetElement = document.getElementById(`${msg.id}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };


  const renderMessage = (msg: Message, index: number, arr: Message[]) => {
    const isUser = msg.role === "me";
    if (isUser && hideIntervieweeMessages) {
      return null;
    }
    const messageStyles = isUser
      ? "bg-rootColor text-black self-end "
      : `bg-gray-200 text-black self-start hover:bg-gray-300 cursor-pointer ${index === arr.length - 1
        ? "border-2 border-gray-500 animate-border-opacity "
        : ""
      } ${msg.animationClass} `;

    return (
      <div key={`${msg.start}-${index}`} className="flex">
        <div
          className={`flex flex-col ${isUser ? "items-end" : "items-start"
            } w-full`}
        >
          <div
            onClick={() => {
              handleMessageClick(msg);
              addAnimationClass(msg);
            }}
            className={`max-w-[80%] text-block p-4 rounded-lg ${messageStyles}`}
            style={{ fontSize: `${fontSize}px` }} // Apply font size
          >
            {msg.text}
          </div>
        </div>
      </div>
    );
  };

  const handleScreenshotEvent = (event: any) => {
    if (disableScreenshotRef.current)
      return message.error(
        "The free version can only use screenshots once, please upgrade the paid version"
      );
    if (
      event.detail &&
      event.detail.type === "FromExtension" &&
      event.detail.image
    ) {
      console.log("截图成功", event.detail.image);
      sendMediaData("image", event.detail.image, "local");
      if (!isProUser) {
        disableScreenshotRef.current = true;
      }
      // message.success("Screenshot sent successfully");
      message.success({
        content: (
          <>
            Screenshot sent successfully
            <img
              src={event.detail.image}
              alt="Screenshot"
              style={{ height: "200px", width: "auto" }}
            />
          </>
        ),
        duration: 5,
      }); // 5秒后自动关闭
    }
  };


  useEffect(() => {
    if (time === 0) return;
    const timer = setTimeout(() => {
      setTime((prevTime) => prevTime + 1000);
    }, 1000);

    if (time > endTime) {
      endInterview();
      setTime(0);
      return () => clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [time, endTime]);

  useEffect(() => {
    if (isProUser) {
      setEndTime(60 * 60 * 1000);
    }
  }, [isProUser]);

  const copilotMessagesText = [...copilotMessagesMap.values()]
    .map((v) => v.text)
    .join("");
  useEffect(() => {
    if (copilotAutoScroll && isCopilotAtBottom) {
      scrollToBottom(copilotEndRef, setIsCopilotAtBottom);
    }
  }, [copilotMessagesText, copilotAutoScroll, isCopilotAtBottom, scrollToBottom]);

  const transcriptMessagesText = [...transcriptMessagesMap.values()]
    .map((v) => v.text)
    .join("");
  useEffect(() => {
    if (transcriptAutoScroll && isTranscriptAtBottom) {
      scrollToBottom(transcriptEndRef, setIsTranscriptAtBottom);
    }
  }, [transcriptMessagesText, transcriptAutoScroll, isTranscriptAtBottom, scrollToBottom]);

  useEffect(() => {
    console.log("Adding event listener for screenshotEvent");
    window.addEventListener("screenshotCaptured", handleScreenshotEvent);

    // Cleanup listener on component unmount
    return () => {
      console.log("Removing event listener for screenshotEvent");
      window.removeEventListener("screenshotCaptured", handleScreenshotEvent);
    };
  }, []);

  useEffect(() => {
    if (isMockInterview && copilotMessagesMap.size === 0 && transcriptMessagesMap.size === 0) {
      setShowBackgroundPrompt(true);
    } else {
      setShowBackgroundPrompt(false);
    }
  }, [isMockInterview, copilotMessagesMap.size, transcriptMessagesMap.size]);

  const settingsMenu = (
    <Menu>
      <Menu.Item>
        <span>Font Size</span>
        <Slider
          min={10}
          max={24}
          value={fontSize}
          onChange={(value) => setFontSize(value)}
          onAfterChange={() => setTooltipVisible(false)}
          onBeforeChange={() => setTooltipVisible(true)}
          tooltip={{ open: tooltipVisible }}
          className="w-48"
        />
      </Menu.Item>
      <Menu.Item>
        <Space>
          <span>Hide My Messages</span>
          <Switch
            checked={hideIntervieweeMessages}
            onChange={(checked) => setHideIntervieweeMessages(checked)}
            className="w-8"
          />
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space>
          <span>Hide Video</span>
          <Switch
            checked={hideVideo}
            onChange={(checked) => setHideVideo(checked)}
            className="w-8"
          />
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="fixed inset-0 bg-gray-100">
      {loading ? (
        <div className="flex items-center justify-center h-full flex-col">
          <Spin size="large" />
          <p className="mt-4 text-lg">
            Preparing your interview, hang tight—you're all set to shine!
          </p>
        </div>
      ) : (
        <>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              position: 'fixed',
              top: 12,
              right: 12,
              fontSize: '16px',
              width: 24,
              height: 24,
              zIndex: 1000,
            }}
          />
          <Button
            type="text"
            icon={hideTranscript ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => setHideTranscript(!hideTranscript)}
            style={{
              position: 'fixed',
              top: 12,
              left: 12,
              fontSize: '16px',
              width: 24,
              height: 24,
              zIndex: 1000,
            }}
          />
          {countdown !== null && (
            <Alert
              message={`Your are reaching session limit! Session will end in ${countdown} seconds.`}
              type="warning"
              showIcon
              className="mb-4"
            />
          )}

          <Layout className={`flex-1 flex relative overflow-hidden ${collapsed ? '' : 'gap-2'} p-2`}>
            {!hideTranscript && (
              <Content className="h-full flex-1 overflow-auto relative bg-white rounded-lg shadow-md p-4">
                <div className="absolute inset-0 flex items-center justify-center text-gray-500 text-6xl font-bold opacity-20 pointer-events-none">
                  Transcript
                </div>
                <div className="flex flex-col mt-6 gap-2 relative z-10 h-[calc(100vh-130px)]">
                  {!hideVideo ? (
                    <>
                      <div className="flex flex-col gap-2">
                        <video
                          ref={videoRef}
                          autoPlay
                          muted
                          className="h-[150px]"
                          width="100%"
                        ></video>
                      </div>
                      <hr className="my-2 border-gray-300" />
                    </>
                  ) : null}
                  <div
                    className="overflow-auto relative px-3 flex flex-col gap-2"
                    onScroll={() => handleScroll(transcriptEndRef, setIsTranscriptAtBottom)}
                    ref={transcriptEndRef}
                  >
                    {showBackgroundPrompt && (
                      <div className="text-center text-gray-500 italic my-4">
                        Say "hello" to start the conversation
                      </div>
                    )}
                    {Array.from(transcriptMessagesMap.values()).map(
                      renderMessage
                    )}
                    <div ref={transcriptEndRef} />
                  </div>
                </div>
              </Content>
            )}

            <Sider
              width={hideTranscript ? '100%' : 'calc(100% - 500px)'}
              collapsible
              collapsed={collapsed}
              onCollapse={setCollapsed}
              className={`bg-white flex-1 w-full rounded-lg shadow-md ${collapsed ? 'sider-collapsed' : 'p-4'}`}
              trigger={null}
              collapsedWidth={0}
            >
              <div className={`${collapsed ? 'hidden' : ''} absolute inset-0 flex items-center justify-center text-gray-500 text-6xl font-bold opacity-20 pointer-events-none`}>
                AI Messages
              </div>
              <div className="relative z-10 mt-6">
                <div
                  className="w-full overflow-auto max-h-[calc(100vh-130px)] px-3 flex flex-col gap-2"
                  onScroll={() => handleScroll(copilotEndRef, setIsCopilotAtBottom)}
                  ref={copilotEndRef}
                >
                  {showBackgroundPrompt && (
                    <div className="text-center text-gray-500 italic my-4">
                      Say "hello" to start the conversation
                    </div>
                  )}
                  {Array.from(copilotMessagesMap.values())
                    .filter((v) => v.text)
                    .map((item, index, arr) => (
                      <div
                        key={item.id}
                        id={item.id.toString()}
                        className={`bg-white rounded-lg p-4 ${item.animationClass
                          } ${index === arr.length - 1
                            ? "border-2 border-gray-300 animate-border-opacity"
                            : ""
                          }`}
                        onClick={() => addAnimationClass(item)}
                        style={{ fontSize: `${fontSize}px` }}
                      >
                        <MarkdownRenderer markdownText={item.text} />
                      </div>
                    ))}
                  <div ref={copilotEndRef}></div>
                </div>
              </div>
            </Sider>
          </Layout>

          <div className="flex justify-between px-4 py-2 bg-gray-100 shadow-md bottom-0 w-full border-gray-300">
            <Space>Time: {formatTime(endTime - time)}</Space>
            <div className="flex gap-4 items-center">
              <Dropdown
                overlay={settingsMenu}
                trigger={["click"]}
                open={dropdownVisible}
                onVisibleChange={handleDropdownVisibleChange}
              >
                <Button
                  icon={<SettingOutlined />}
                  type="text"
                  className="p-0"
                />
              </Dropdown>
              <Button
                onClick={handleEndInterview}
                type="primary"
                className="px-4 py-2 rounded-md bg-indigo-600 text-white"
              >
                End
              </Button>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};


export default ChatFrame;
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  createGoal,
  getGoalById,
  listGoalsByUser,
  countGoalsByUser,
  updateGoal,
  archiveGoal,
  type Goal as GoalType,
} from "../../api/goals";
import { useAuth } from "../../contexts/AuthContext";
import { Button, Form, message, Popconfirm, Radio, Space, Table } from "antd";
import CreateModal from "./components/CreateModal";
import CustomTable from "../../components/CustomTable";

type Values = GoalType;

const Goal: React.FC = () => {
  const { session } = useAuth();
  const [goals, setGoals] = useState<GoalType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [radioValue, setRadioValue] = useState("active");

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<Values>();
  const [open, setOpen] = useState(false);

  const userId = session?.user.id;

  const fetchGoals = async () => {
    if (!session) {
      setLoading(false);
      return;
    }
    try {
      const userId = session.user.id;
      const { goals } = await listGoalsByUser(userId);
      setGoals(goals);
    } catch (err) {
      message.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createHandler = async (values: Values) => {
    const o: any = {
      category: values.category,
      company: values.company,
      company_details: values.company_details,
      job_description: values.job_description,
      position: values.position,
    }
    setFormValues(o);
    createGoal({ ...o, user_id: userId })
    .then(() => {
      message.success("Job application created successfully!");
      setOpen(false);
      form.resetFields();

      fetchGoals();
    })
    .catch((error) => {
      message.error(error.message);
    });
  };

  const updateHandler = async (values: Values) => {
    console.log(values);
    setFormValues(values);
    updateGoal(values.id, {...values, user_id: userId}).then(() => {
      message.success("Job application updated successfully!");
      setOpen(false);
      form.resetFields();

      fetchGoals();
    }).catch((error) => {
      message.error(error.message);
    });
  };

  const editHandler = async (record: GoalType) => {
    setFormValues(record);
    setIsEditing(true);
    setTimeout(() => {
      form.setFieldsValue(record);
    }, 100);
    setOpen(true);
  };

  const archiveHandler = async (id: string) => {
    archiveGoal(id).then(() => {
      message.success("Job application archived successfully!");

      fetchGoals();
    }).catch((error) => {
      message.error(error.message);
    });
  };

  const columns = [
    {
      title: "Position",
      dataIndex: "position",
      key: "Position",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "Company",
    },
    {
      title: "Company Detail",
      dataIndex: "company_details",
      key: "Company Detail",
    },
    {
      title: "Job Description",
      dataIndex: "job_description",
      key: "Job Description",
    },
    {
      title: "Actions",
      key: "Actions",
      render: (_, record: GoalType) => {
        return (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                /* Handle edit goal */
                editHandler(record);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Archive the job application?"
              description="Are you sure to archive this job application? You cannot undo this action."
              onConfirm={() => {
                /* Handle archive goal */
                archiveHandler(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>Archive</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    fetchGoals();
  }, [session]);

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      <div className="flex justify-between mb-4">
      <div>
      <h1 className="text-2xl font-bold">Job Applications</h1>
      </div>
      <Button
        type="primary"
        onClick={() => {
          form.resetFields();
          setIsEditing(false);
          setOpen(true);
        }}
      >
        Create
      </Button>
      </div>
  
      <CreateModal
        key={isEditing ? "edit" : "create"}
        open={open}
        setOpen={setOpen}
        formValues={formValues}
        form={form}
        isEditing={isEditing}
        onFinish={e => {
          if(isEditing){
            updateHandler(e);
          }else{
            createHandler(e);
          }
        }}
      />
      <CustomTable loading={loading} dataSource={goals} columns={columns} />
    </div>
  );
};

export default Goal;
import { Avatar, Button } from "antd";
import { useAuth } from "../../contexts/AuthContext";

type Props = {
  next: () => void;
};
const Step1 = ({ next }: Props) => {
  const { session } = useAuth();

  console.log(session);
  return (
    <div
      className="flex flex-col items-center justify-center gap-4 text-center
    w-[400px] h-[300px]
    "
    >
      <Avatar size={64} />
      <h1 className="text-xl font-bold">
        Hi, {session?.user?.email || "Guest"}
      </h1>
      <p className="text-gray-500 ">🎉 Welcome to Nextoffer! 🎉</p>
      <Button type="primary" onClick={next}>
        Let's do it!
      </Button>
    </div>
  );
};

export default Step1;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Icon, {
  CloseOutlined,
  LogoutOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  RobotOutlined, // Import a new icon for Mock Interview
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  ConfigProvider,
  Drawer,
  Layout,
  Menu,
  message,
  Modal,
  Tooltip,
  theme,
  Space,
} from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import goalsIcon from "./icons/goals.svg?react";
import documentsIcon from "./icons/documents.svg?react";
import interviewsIcon from "./icons/interviews.svg?react";
import { supabase } from "../lib/supabaseClient";
import { ProfileModal } from "./ProfileModal"; // Import the ProfileModal component
import { useUserStatus } from "../contexts/UserStatusContext";
import WelcomeTour from "./WelcomeTour";
import mixpanel from "../lib/mixpanel";
import { useAuth } from "../contexts/AuthContext";

const { Sider, Content } = Layout;

const AntdLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [breakpoint, setBreakpoint] = useState(false);
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState<any>(null);
  const { pathname } = useLocation();

  const { isProUser, totalInterviews } = useUserStatus();

  const [isTourOpen, setIsTourOpen] = useState(false);

  const { session } = useAuth();


  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  const labelStyle = (label: string) => (
    <span style={{ fontWeight: 500, fontSize: 14 }}>{label}</span>
  );

  const menuList = [
    {
      type: 'group',
      label: 'Dashboard',
      children: [
        {
          key: "/mock",
          icon: <RobotOutlined />,
          label: labelStyle("Mock Interview"),
        },
        {
          key: "/interview",
          icon: <Icon component={interviewsIcon} />,
          label: labelStyle("Interview Assistant"),
        },
        {
          key: "/jobs",
          icon: <Icon component={goalsIcon} />,
          label: labelStyle("Job Applications"),
        },
        {
          key: "/documents",
          icon: (
            <Icon
              style={{
                fill: colorPrimary,
                color: colorPrimary,
                stroke: colorPrimary,
              }}
              component={documentsIcon}
            />
          ),
          label: labelStyle("Resume"),
        },
      ],
    },
    {
      type: 'group',
      label: 'Resources',
      children: [
        {
          key: "onboarding-video",
          label: labelStyle("Interview Assistant Demo"),
          onClick: () => window.open("https://www.youtube.com/watch?v=xoQw3duNaN4", "_blank"),
        },
        {
          key: "copilot-video",
          label: labelStyle("Coding Copilot Demo"),
          onClick: () => window.open("https://www.youtube.com/watch?v=Vp2MRMhpWTs", "_blank"),
        },
        {
          key: "discord-community",
          label: labelStyle("Join Discord Community"),
          onClick: () => window.open("https://discord.gg/FvvD3WmGgE", "_blank"),
        },
        {
          key: "contact-support",
          label: labelStyle("Contact Support"),
          onClick: () => window.location.href = "mailto:hi@nextoffer.ai",
        },
      ],
    },
  ];

  const drawerStyles: DrawerStyles = {
    mask: {
      backdropFilter: "blur(10px)",
    },
    content: {
      boxShadow: "-10px 0 10px #666",
    },
    header: {
      borderBottom: `1px solid ${colorPrimary}`,
    },
    body: {},
    footer: {},
  };

  const onClose = () => {
    setOpen(false);
  };

  const menuClickHandler = ({ item, key, keyPath }: any) => {
    // Define keys that should not trigger navigation
    const nonNavigableKeys = ["onboarding-video", "discord-community", "contact-support"];

    // Return early if the key is in the non-navigable list
    if (nonNavigableKeys.includes(key)) {
      return;
    }

    setSelectedKeys(keyPath);
    navigate(keyPath.join("/"));
  };

  const signOut = () => {
    supabase.auth
      .signOut()
      .then(() => {
        console.log("User signed out successfully");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
        message.error(error.error_description || error.message);
      });
  };

  // 判断是否是第一次登录
  useEffect(() => {
    const hasLoggedIn = localStorage.getItem("hasLoggedIn");
    if (!hasLoggedIn && session) {
      setIsTourOpen(true);
      mixpanel.track("First Time User");
      localStorage.setItem("hasLoggedIn", "true");
    }
  }, [session]);

  useEffect(() => {

    if (!pathname || pathname === "/") {
      setSelectedKeys(["/interview"]);
      navigate("/interview");
      return;
    }
    setSelectedKeys([pathname]);
  }, [pathname]);

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleGoPricing = () => {
    navigate("/pricing");
  };

  const menuContent = (
    <div className="mb-8 flex flex-col gap-4 justify-between items-center h-full">
      <div className="w-full">
        <div className="h-12 mt-4 flex justify-center items-center font-bold text-2xl">
          {collapsed ? "N" : "Nextoffer"}
        </div>
        <Menu
          inlineCollapsed={collapsed}
          onClick={menuClickHandler}
          theme="light"
          mode="inline"
          defaultSelectedKeys={["interview"]}
          selectedKeys={selectedKeys}
          items={menuList}
        />
      </div>

      <div className="flex flex-col gap-2 items-center mb-4">
        <Avatar
          style={{ backgroundColor: "#6366f1", verticalAlign: "middle" }}
          src={user?.user_metadata?.avatar_url}
          icon={!user?.user_metadata?.avatar_url && <UserOutlined />}
          className="cursor-pointer mb-4"
          size="large"
          onClick={handleOpenModal}
        >
          User
        </Avatar>

        {collapsed || isProUser === null ? (
          <></>
        ) : (
          <div className="flex flex-col gap-2 items-center">
            <div className="text-xs text-gray-500 text-center">
              {isProUser ? (
                "Your Plan: Pro"
              ) : (
                <>
                  Your Plan: Free
                  <br />
                  {Math.max(0, 3 - totalInterviews)} 15-minute trial session(s) left.
                </>
              )}
            </div>
            {isProUser ? (
              <></>
            ) : (
              <Button type="primary" onClick={handleGoPricing}>
                Upgrade to Pro 🚀
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{
          backgroundColor: colorBgContainer,
          padding: "0",
        }}
        width={250}
        breakpoint="lg"
        collapsedWidth="80px"
        trigger={null}
        onBreakpoint={(broken) => {
          setBreakpoint(broken);
        }}
        onCollapse={(collapsed) => {
          setCollapsed(collapsed);
        }}
      >
        {menuContent}
      </Sider>
      <ConfigProvider
        drawer={{
          styles: drawerStyles,
        }}
      >
        <Drawer
          closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
          title={null}
          placement="left"
          onClose={onClose}
          closable={false}
          open={open}
          width={256}
          bodyStyle={{ padding: "0" }}
          rootStyle={{ padding: "0", margin: "0" }}
          style={{
            padding: "0",
            margin: "0",
            backgroundColor: colorBgContainer,
          }}
        >
          {menuContent}
        </Drawer>
      </ConfigProvider>
      <Layout>
        <Content
          style={{
            paddingTop: 0,
            minWidth: "800px",
            overflowY: "auto",
            backgroundColor: colorBgContainer,
          }}
        >
          <div
            style={{
              padding: "10px",
              minHeight: "calc(100vh - 50px)",
              maxHeight: "100vh",
              overflowY: "auto",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
      {user && (
        <ProfileModal
          signOut={signOut}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          user={user}
        />
      )}

      <WelcomeTour
        open={isTourOpen}
        onClose={() => setIsTourOpen(false)}
      />
    </Layout>
  );
};

export default AntdLayout;
import { supabase } from "../lib/supabaseClient";
import { Database } from "../database.types";

export type Question = Database["public"]["Tables"]["questions"]["Row"];

interface FetchQuestionsParams {
  limit: number;
  offset: number;
  roles?: Record<string, any>;
  companies?: Record<string, any>;
  category?: string[];
  orderBy: 'created_at' | 'popularity_score';
}

// Fetch interview questions with pagination and filters
export const fetchInterviewQuestions = async ({
  limit,
  offset,
  roles,
  companies,
  category,
  orderBy,
}: FetchQuestionsParams): Promise<{ questions: Question[]; total: number }> => {
  let query = supabase
    .from("questions")
    .select("*", { count: "exact" })
    .range(offset, offset + limit - 1);

  if (roles && roles.length > 0) {
    query = query.or(roles.map(role => `roles->>'role' = '${role}'`).join(' or '));
  }

  if (companies) {
    query = query.contains("companies", companies);
  }

  if (category) {
    query = query.overlaps("category", category);
  }

  query = query.order(orderBy, { ascending: false });

  const { data, error, count } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return { questions: data as Question[], total: count || 0 };
};
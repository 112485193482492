import { message } from 'antd';
import { useState, useRef, useCallback, useEffect } from "react";
import { AudioStatusEnum } from "../types";

const useScreenSharing = (onStopScreenSharing: () => void) => {
  const [isSharing, setIsSharing] = useState<boolean>(false); // 共享状态
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null); // 屏幕流
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null); // 麦克风音频流
  const [
    combinedAudioStream,
    setCombinedAudioStream,
  ] = useState<MediaStream | null>(null); // 合并后的音频流
  const videoRef = useRef<HTMLVideoElement>(null);

  const [screenAudioStatus, setScreenAudioStatus] = useState<AudioStatusEnum>(
    AudioStatusEnum.UNAVAILABLE
  ); // 屏幕音频状态
  const [microphoneStatus, setMicrophoneStatus] = useState<AudioStatusEnum>(
    AudioStatusEnum.UNAVAILABLE
  ); // 麦克风状态

  const [volume, setVolume] = useState<number>(0); // Add volume state
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");

  useEffect(() => {
    const getAudioDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(device => device.kind === "audioinput");
      setAudioDevices(audioInputDevices);
      if (audioInputDevices.length > 0) {
        setSelectedDeviceId(audioInputDevices[0].deviceId);
      }
    };
    getAudioDevices();
  }, [microphoneStatus]);

  const analyzeAudio = (audioStream: MediaStream) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(audioStream);
    microphone.connect(analyser);
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    let volumeHistory: number[] = [];
    const historyLength = 5; // 平滑窗口大小

    const updateVolume = () => {
      analyser.getByteTimeDomainData(dataArray);
      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        const value = (dataArray[i] - 128) / 128; // 正确计算偏移
        sum += value * value;
      }
      const rms = Math.sqrt(sum / bufferLength);
      const volumeLevel = Math.min(Math.max(rms * 100, 0), 100); // 缩放到 0-100

      // 添加到历史记录并计算平均值
      volumeHistory.push(volumeLevel);
      if (volumeHistory.length > historyLength) {
        volumeHistory.shift();
      }
      const smoothedVolume = volumeHistory.reduce((a, b) => a + b, 0) / volumeHistory.length;

      setVolume(Math.round(smoothedVolume)); // 确保是整数
      requestAnimationFrame(updateVolume);
    };

    updateVolume();
  };

  // 停止屏幕共享和音频流
  const handleStop = useCallback(() => {
    setIsSharing(false);
    console.log("停止屏幕共享和音频流", screenStream, audioStream);
    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
    }

    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }

    setScreenStream(null);
    setAudioStream(null);
    setCombinedAudioStream(null);
    setScreenAudioStatus(AudioStatusEnum.UNAVAILABLE);
    setMicrophoneStatus(AudioStatusEnum.UNAVAILABLE);

    if (onStopScreenSharing) {
      onStopScreenSharing();
    }
  }, [screenStream, audioStream]);

  // 开始屏幕共享
  const handleStart = async (isMockInterview?: boolean) => {
    try {
      let screenStream: MediaStream | null = null;
      let screenAudioTracks: MediaStreamTrack[] = [];

      if (!isMockInterview) {
        // 非模拟面试时，屏幕共享是必需的
        screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });

        // 检查是否选择了浏览器标签页
        const isTabSelected = screenStream.getTracks().every((track) => {
          if (track.kind === "video") {
            const videoSettings = track.getSettings();
            return videoSettings.displaySurface !== "browser";
          }
          return false;
        });

        if (isTabSelected) {
          // 弹出错误信息 英文
          message.info("To ensure Nextoffer can receive your interview audio, please share a browser tab with audio enabled.");
          handleStop();
          return;
        }

        screenStream.getVideoTracks()[0].onended = handleStop;
        setScreenStream(screenStream);

        screenAudioTracks = screenStream.getAudioTracks();
        setScreenAudioStatus(
          screenAudioTracks.length > 0
            ? AudioStatusEnum.AVAILABLE
            : AudioStatusEnum.UNAVAILABLE
        );
      }

      // 获取麦克风音频流
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // 更新状态
      setAudioStream(audioStream);

      if (videoRef.current && screenStream) {
        videoRef.current.srcObject = screenStream;
      }

      const micAudioTracks = audioStream.getAudioTracks();

      setMicrophoneStatus(
        micAudioTracks.length > 0
          ? AudioStatusEnum.AVAILABLE
          : AudioStatusEnum.UNAVAILABLE
      );

      // 合并音频流
      const audioContext = new AudioContext();
      const micSource = audioContext.createMediaStreamSource(audioStream);
      const combinedAudioDestination = audioContext.createMediaStreamDestination();

      if (screenAudioTracks.length > 0) {
        const screenAudioSource = audioContext.createMediaStreamSource(
          new MediaStream(screenAudioTracks)
        );
        const channelMerger = audioContext.createChannelMerger(2);

        micSource.connect(channelMerger, 0, 0); // 麦克风连接到第一声道
        screenAudioSource.connect(channelMerger, 0, 1); // 屏幕音频连接到第二声道

        channelMerger.connect(combinedAudioDestination);
      } else {
        // 如果没有屏幕音频，直接使用麦克风流
        micSource.connect(combinedAudioDestination);
      }

      setCombinedAudioStream(combinedAudioDestination.stream);

      // 设置事件监听器
      audioStream.getTracks().forEach((track) => {
        track.onended = handleStop; // 如果麦克风流停止，触发handleStop
      });

      // 监听流的停止事件
      if (screenStream) {
        screenStream.oninactive = () => {
          console.log("屏幕流停止");
          handleStop();
          audioStream.getTracks().forEach((track) => track.stop());
        };
      }

      audioStream.oninactive = handleStop;
      setIsSharing(true);
    } catch (error) {
      console.error("获取屏幕或麦克风权限失败:", error);
      setScreenAudioStatus(AudioStatusEnum.ERROR);
      setMicrophoneStatus(AudioStatusEnum.ERROR);
      handleStop();
      message.error("Failed to get screen or microphone permission");
    }
  };

  const handleStartMicrophoneOnly = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined },
      });

      setAudioStream(audioStream);
      analyzeAudio(audioStream); // Analyze audio for volume

      const micAudioTracks = audioStream.getAudioTracks();
      setMicrophoneStatus(
        micAudioTracks.length > 0
          ? AudioStatusEnum.AVAILABLE
          : AudioStatusEnum.UNAVAILABLE
      );

      const audioContext = new AudioContext();
      const micSource = audioContext.createMediaStreamSource(audioStream);
      const combinedAudioDestination = audioContext.createMediaStreamDestination();

      micSource.connect(combinedAudioDestination);
      setCombinedAudioStream(combinedAudioDestination.stream);

      audioStream.getTracks().forEach((track) => {
        track.onended = handleStop;
      });

      // setIsSharing(true);
    } catch (error) {
      console.error("获取麦克风权限失败:", error);
      // 弹出错误信息 英文
      message.error("Failed to get microphone permission");
      setMicrophoneStatus(AudioStatusEnum.ERROR);
    }
  };

  return {
    screenStream,
    audioStream,
    combinedAudioStream, // 返回合并后的音频流
    isSharing,
    handleStart,
    handleStop,
    videoRef,
    screenAudioStatus,
    microphoneStatus,
    handleStartMicrophoneOnly,
    volume, // Return volume
    audioDevices,
    selectedDeviceId,
    setSelectedDeviceId,
  };
};

export default useScreenSharing;

import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Skeleton, message } from "antd";
import { IProps } from "../types";
import { createGoal, listGoalsByUser, type Goal as GoalType } from "../../../api/goals";
import { useAuth } from "../../../contexts/AuthContext";
import CreateModal from "../../Jobs/components/CreateModal";
import mixpanel from "../../../lib/mixpanel";
import NoDataView from "../../../components/NoData";
import { categoryToInterviewTypes, jobCategories } from "../constants";




const getCategoryLabel = (value: string) => {
  const category = jobCategories.find(cat => cat.value === value);
  return category ? category.label : value;
};

const Next1 = ({ onNext, onBack }: IProps) => {
  const { session } = useAuth();
  const [goals, setGoals] = useState<GoalType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchGoals = async () => {
    setLoading(true);
    if (!session) {
      return;
    }
    try {
      const userId = session.user.id;
      const { goals } = await listGoalsByUser(userId);
      setGoals(goals);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoals();
  }, [session]);

  const handleAddNewJob = () => {
    setIsModalOpen(true);
    mixpanel.track("Add New Job Clicked");
  };

  const handleModalFinish = async (values: GoalType) => {
    console.log("New position created:", values);
    if (!session) {
      message.error("You must be logged in to create a new position.");
      setLoading(false);
      return;
    }
    const userId = session.user.id;
    createGoal({ ...values, user_id: userId })
      .then(() => {
        setIsModalOpen(false);
        fetchGoals();
        mixpanel.track("Job Application Created", {
          category: values.category,
          position: values.position,
          company: values.company,
        });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div className="flex justify-end mb-4">
        <Button type="primary" onClick={handleAddNewJob}>
          Add new application
        </Button>
      </div>

      <Row gutter={[16, 16]}>
        {loading ? (
          <Skeleton active />
        ) : goals.length > 0 ? (
          goals.map((goal) => (
            <Col key={goal.id} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Card
                hoverable
                className="h-full cursor-pointer"
                onClick={() => {
                  const interviewTypes = categoryToInterviewTypes[goal.category] || ["general", "management"];
                  onNext({ ...goal, interviewTypes });
                  mixpanel.track("Job Application Selected", {
                    category: goal.category,
                    position: goal.position,
                    company: goal.company,
                  });
                }}
              >
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div className="font-bold text-lg">{goal.position}</div>
                    <div>{goal.company}</div>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <div>{getCategoryLabel(goal.category)}</div>
                  </div>
                </div>
              </Card>
            </Col>
          ))
        ) : (
          <div className="flex items-center justify-center w-full h-[400px]">
            <NoDataView description="No job applications yet. Add one above." />
          </div>
        )}
      </Row>

      <CreateModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        formValues={{}}
        form={null as any}
        onFinish={handleModalFinish}
        isEditing={false}
      />
    </>
  );
};

export default Next1;

import { Button } from "antd";

const Step4 = ({ next }: { next: () => void }) => (
  <div className="flex flex-col items-center justify-center bg-gray-100">
    <div className="bg-white p-8 text-center">
      <h1 className="text-3xl font-bold mb-4">Congrats!</h1>
      <p className="text-lg mb-6">You've successfully completed the tour.</p>
      <Button type="primary" className="w-[300px] mb-4" onClick={next}>
        Let's Get Started
      </Button>
      <p className="text-sm">
        Get started video{" "}
        <a
          href="https://www.youtube.com/watch?v=xoQw3duNaN4"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          product demo video
        </a>
        .
      </p>
    </div>
  </div>
);

export default Step4;

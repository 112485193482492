import { TourProps, Tour } from "antd";
import { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

interface TourStep {
  onClose: () => void;
  open: boolean;
}

const WelcomeTour = ({ onClose, open }: TourStep) => {
  const [current, setCurrent] = useState(0);

  const handleClose = () => {
    onClose();
    setCurrent(0);
  };

  const steps: TourProps["steps"] = [
    {
      title: <></>,
      description: <Step1 next={() => setCurrent(1)} />,
      placement: "center",
      nextButtonProps: {
        style: {
          display: "none",
        },
      },
      prevButtonProps: {
        style: {
          display: "none",
        },
      },
    },
    {
      title: <></>,
      description: <Step2 next={() => setCurrent(2)} />,
      placement: "center",
      nextButtonProps: {
        style: {
          display: "none",
        },
      },
      prevButtonProps: {
        style: {
          display: "none",
        },
      },
    },
    {
      title: <></>,
      description: <Step3 next={() => setCurrent(3)} />,
      placement: "center",
      nextButtonProps: {
        style: {
          display: "none",
        },
      },
      prevButtonProps: {
        style: {
          display: "none",
        },
      },
    },
    {
      title: <></>,
      description: <Step4 next={handleClose} />,
      placement: "center",
      nextButtonProps: {
        style: {
          display: "none",
        },
      },
      prevButtonProps: {
        style: {
          display: "none",
        },
      },
    },
  ];

  return (
    <Tour
      className="w-full"
      current={current}
      onChange={setCurrent}
      closable={false}
      open={open}
      onClose={onClose}
      steps={steps}
      indicatorsRender={() => <></>}
    />
  );
};

export default WelcomeTour;

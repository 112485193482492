import { InterviewStatus, InterviewType } from "../../api/interviews";

export const interviewTypeOptions = [
  { value: InterviewType.CODING, label: "Coding" },
  { value: InterviewType.SYSTEM_DESIGN, label: "System Design" },
  { value: InterviewType.BEHAVIORAL, label: "Behavioral" },
];

export const interviewStatusOptions = [
  { value: InterviewStatus.NOT_STARTED, label: "Not Started" },
  { value: InterviewStatus.INTERVIEWING, label: "Interviewing" },
  { value: InterviewStatus.FINISHED, label: "Finished" },
];

export const JOB_CATEGORIES = {
    ENGINEERING: "engineering",
    DATA_SCIENCE: "data_science",
    PRODUCT_MANAGEMENT: "product_management",
    FINANCE: "finance",
    CONSULTING: "consulting",
    USER_RESEARCH: "user_research",
    MARKETING: "marketing",
    SALES: "sales",
    DESIGN: "design",
    CUSTOMER_SUPPORT: "customer_support",
    OTHERS: "others",
  };
  
  export const jobCategories = [
    { value: JOB_CATEGORIES.ENGINEERING, label: "💻 Engineering" },
    { value: JOB_CATEGORIES.DATA_SCIENCE, label: "📊 Data Science" },
    { value: JOB_CATEGORIES.PRODUCT_MANAGEMENT, label: "💡 Product Management" },
    { value: JOB_CATEGORIES.USER_RESEARCH, label: "🧑‍🔬 User Research" },
    { value: JOB_CATEGORIES.FINANCE, label: "💰 Finance" },
    { value: JOB_CATEGORIES.CONSULTING, label: "👥 Consulting" },
    { value: JOB_CATEGORIES.MARKETING, label: "📣 Marketing" },
    { value: JOB_CATEGORIES.SALES, label: "💼 Sales" },
    { value: JOB_CATEGORIES.DESIGN, label: "🎨 Design" },
    { value: JOB_CATEGORIES.CUSTOMER_SUPPORT, label: "🎧 Customer Support" },
    { value: JOB_CATEGORIES.OTHERS, label: "🔹 Others" },
  ];

  export const categoryToRolesMap = {
    [JOB_CATEGORIES.ENGINEERING]: ["hardware-engineer", "swe", "frontend-engineer", "backend-engineer", "test-engineer", "tpm", "qa-engineer", "solutions-architect", "sre", "mobile-engineer", "ml-engineer", "em", "security"],
    [JOB_CATEGORIES.DATA_SCIENCE]: ["data-science", "data-analyst", "data-engineer", "ml-engineer"],
    [JOB_CATEGORIES.PRODUCT_MANAGEMENT]: ["product-designer", "pmm", "product-analyst", "pm"],
    [JOB_CATEGORIES.FINANCE]: ["investment-banking", "private-equity"],
    [JOB_CATEGORIES.CONSULTING]: ["management-consultant", "bizops"],
    [JOB_CATEGORIES.MARKETING]: ["marketing-manager", "growth-marketing-manager", "pmm"],
    [JOB_CATEGORIES.SALES]: ["sales-representative", "account-manager", "business-development"],
    [JOB_CATEGORIES.DESIGN]: ["product-designer", "design-manager"],
    [JOB_CATEGORIES.CUSTOMER_SUPPORT]: ["customer-success-manager"],
    [JOB_CATEGORIES.OTHERS]: ["chief-of-staff"],
  };

  export const categoryToInterviewTypes = {
    [JOB_CATEGORIES.ENGINEERING]: ["system_design", "management", "coding", "ml_system_design", "general"],
    [JOB_CATEGORIES.DATA_SCIENCE]: ["ml_system_design", "general", "management", "coding", "system_design"],
    [JOB_CATEGORIES.PRODUCT_MANAGEMENT]: ["product_management", "general", "management"],
    [JOB_CATEGORIES.USER_RESEARCH]: ["user_research", "general", "management"],
    [JOB_CATEGORIES.FINANCE]: ["general", "management"],
    [JOB_CATEGORIES.CONSULTING]: ["general", "management"],
    [JOB_CATEGORIES.MARKETING]: ["general", "management"],
    [JOB_CATEGORIES.SALES]: ["general", "management"],
    [JOB_CATEGORIES.DESIGN]: ["general", "management"],
    [JOB_CATEGORIES.CUSTOMER_SUPPORT]: ["general", "management"],
    [JOB_CATEGORIES.OTHERS]: ["general", "management"],
  };
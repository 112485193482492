import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import { useEffect, useRef } from "react";
import customCss from "./index.css?raw";
import githubCss from "highlight.js/styles/github.css?raw";
// import shaderString from './shader.glsl?raw'
// Initialize Markdown-it with highlight.js

const md = new MarkdownIt({
  highlight: function(str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) {}
    }
    return hljs.highlightAuto(str).value;
  },
});

const MarkdownRenderer = ({ markdownText }: { markdownText: string }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const currentElement = containerRef.current;
    if (!currentElement) return;

    let shadowRoot = currentElement.shadowRoot;
    if (!shadowRoot) {
      shadowRoot = currentElement.attachShadow({ mode: "open" });
    } else {
      shadowRoot.innerHTML = ""; // 清空现有的 Shadow DOM
    }

    const style = document.createElement("style");
    style.textContent = githubCss + customCss;
    shadowRoot.appendChild(style);

    const htmlContent = md.render(markdownText);
    shadowRoot.innerHTML += `
      <div class="markdown-body">
        ${htmlContent}
      </div>
    `;
  }, [markdownText]);

  return <div ref={containerRef}></div>;
};

export default MarkdownRenderer;

import { useState, useRef } from 'react';
import { Card, Steps, Button, Upload, message } from 'antd';
import { UploadOutlined, FileTextOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';

const { Step } = Steps;

const stepsItems = [
  {
    title: "Step 1",
    description: "Upload Resume",
  },
  {
    title: "Step 2",
    description: "Review and Confirm",
  },
  {
    title: "Step 3",
    description: "AI Analysis",
  },
];

const Resume = () => {
  const [current, setCurrent] = useState(0);
  const [file, setFile] = useState<RcFile | null>(null);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const uploadRef = useRef<any>(null);

  const onChange = (value: number) => {
    if (value > current) return;
    setCurrent(value);
  };

  const handleUpload = (info: any) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      setFile(info.file.originFileObj);
      setFileList([info.file]);
      setCurrent(1);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const renderContent = () => {
    switch (current) {
      case 0:
        return (
          <Upload
            ref={uploadRef}
            accept=".pdf"
            onChange={handleUpload}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Click or drag file to this area to upload</Button>
          </Upload>
        );
      case 1:
        return (
          <div>
            <p>Uploaded file: {file?.name}</p>
            <Button onClick={() => setCurrent(2)}>Confirm and Analyze</Button>
          </div>
        );
      case 2:
        return <p>AI is analyzing your resume...</p>;
      default:
        return null;
    }
  };

  return (
    <div className="flex gap-4 items-center justify-center w-full h-full">
      <Card className="w-[300px] h-full">
        <div className="mb-8">
          <h1 className="text-xl font-bold">Resume Analyzer</h1>
          <p className="text-sm text-gray-500">Optimize your resume with AI</p>
        </div>
        <Steps
          current={current}
          onChange={onChange}
          direction="vertical"
          items={stepsItems}
        />
        <Button
          icon={<FileTextOutlined />}
          onClick={() => uploadRef.current?.upload.click()}
          className="mt-4 w-full"
        >
          Upload Resume
        </Button>
      </Card>
      <div className="flex-1 w-full h-full">
        <Card className="h-full">
          {renderContent()}
        </Card>
      </div>
    </div>
  );
};

export default Resume;